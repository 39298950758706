import { Component, Input, OnInit, Output } from '@angular/core';
import { interval, Subscription } from 'rxjs';

import { global } from '../../services/global';

import { UserService } from 'src/app/services/user.service';
import { LoginComponent } from '../login/login.component';

@Component({
  selector: 'app-nfc-modal',
  templateUrl: './nfc-modal.component.html',
  styleUrls: ['./nfc-modal.component.scss'],
})
export class NfcModalComponent implements OnInit {
  displayModal = false;
  private tokenExistSubscription: Subscription = new Subscription();
  private tokenDeleteSubscription: Subscription = new Subscription();
  NFCToken: string = '';
  public token: any;
  public identity: any;
  public workplace: any;
  public userStand: any;

  constructor(
    private _userService: UserService
  ) {} //private checkIdle: BnNgIdleService

  ngOnInit(): void {
      //this.borrarToken();
      //console.log("componente modal lanzado");
      this.iniciarAutoCheck();
  }

  private iniciarAutoCheck() {
    this.tokenExistSubscription = interval(500).subscribe((val) => {
      this.comprobarTokenNFC();
    });
  }

  private comprobarTokenNFC() {
    let tokenNFC = localStorage.getItem('NFCToken');
    if (tokenNFC) {
      this.displayModal = false;
      this.tokenExistSubscription.unsubscribe();
      this.checkNFCToken(tokenNFC);
      this.borrarToken();
      //console.log("Todo OK");
      //this.ngOnInit();
    } else {
      this.displayModal = true;
    }
  }

  private borrarToken(){
    localStorage.removeItem('NFCToken');
  }

  private reiniciarProceso() {
    this.borrarToken();
    this.tokenDeleteSubscription.unsubscribe();
    this.iniciarAutoCheck();
  }

  private checkNFCToken(NFCToken: string): boolean {
    let valido = false;
    this._userService.checkNFCToken(NFCToken).subscribe(
      (response) => {
        // TOKEN
        if (response.status != 'error') {
          this.token = response;
          //console.log("tenemos token de sesión");
          // Recojo el usuario;
          valido = this.getIdentidad(NFCToken);
        }
      },
      (error) => {
        console.log(<any>error);
        this.ngOnInit();
      }
    );
    return valido;
  }

  private getIdentidad(NFCToken: string) {
    let valido = false;
    this._userService.checkNFCToken(NFCToken, true).subscribe(
      (response) => {
        this.identity = response;

        // Persisto los datos del usuario identificado
        localStorage.setItem('token', this.token);
        localStorage.setItem('identity', JSON.stringify(this.identity));
        // Incluyo su lugar de trabajo
        valido = this.getLugarTrabajo();
      },
      (error) => {
        console.log(<any>error);
        valido = false;
      }
    );
    return valido;
  }

  private getLugarTrabajo(): boolean {
    let valido = false;
    this._userService.getUserPlace(this.identity.employee_id).subscribe(
      (response) => {
        this.workplace = response;
        //console.log('workplace:',this.workplace);
        
        localStorage.setItem(
          'workplace',
          JSON.stringify(this.workplace.place[0])
        );
        valido = true;
        //console.log('rol:', this.identity.role);
        if (this.identity.spa_list.includes('COLA')) {
          this.getCola();
        }
      },
      (error) => {
        console.log(<any>error);
        valido = false;
      }
    );
    return valido;
  }

  private getCola() {
    let valido = false;
    this._userService.getUserStand(parseInt(this.identity.employee_id)).subscribe(
      (response) => {
        this.userStand = response.stand[0];
        console.log(response);
        localStorage.setItem('userStand', JSON.stringify(this.userStand));
        valido = true
      },
      (error) => {
        console.log(<any>error);
      }
    );
    return valido;
  }

  showModalDialog() {
    this.displayModal = !this.displayModal;
  }
}
