import { Component, DoCheck, Inject, OnInit } from '@angular/core';
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, Router } from '@angular/router';
import { DOCUMENT } from '@angular/common';
import { interval, Subscription } from 'rxjs';

import { User } from './models/user';

import { UserService } from './services/user.service';
import { global } from './services/global';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [UserService],
})
export class AppComponent implements OnInit, DoCheck {
  private updateSubscription: Subscription | undefined;
  title = 'portal-acceso';
  public identity: any;
  public token: any;
  public user: User;
  public ventanaLogin: boolean = false;
  public useNFC: boolean = false;
  public cargando: boolean = false;
  public loginByNfc: boolean = false;

  constructor(
    private _title: Title,
    public _userService: UserService,
    private _route: ActivatedRoute,
    private _router: Router,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.user = new User(1, '', '', '', '', 0, '');
    this.loadUser();
  }

  ngOnInit() {
    localStorage.removeItem('NFCToken');
    if (global.entorno != 'local') {
      if (location.protocol === 'http:') {
        window.location.href = location.href.replace('http', 'https');
      }
    }
    this.cargaNFC();
    
    //this.checkLastAutoOutApp();
  }

  private cargaNFC() {
    //console.log(window.location.href);
    if(window.location.href.includes("?")) {
      localStorage.clear();
      let path = window.location.href;
      let array = path.split("?");
      path = array[0] + "/" + array[1];
      let path2 = array[1].split("/");
      let token = path2[1];
      this._router.navigate(["/comprobar-nfc/",token]);
      localStorage.setItem('NFCToken', token);
      //window.location.replace(path);
      //console.log("path corregido a:", path);
      
    }
  }

  ngDoCheck() {
    this.loadUser();
    this._title.setTitle(this.identity?.name + " CLU");
  }

  switchToNFC() {
    this.loginByNfc = true;
  }

  iniciarCarga() {
    this.updateSubscription = interval(200).subscribe((val) => {
      if (this.ventanaLogin == false) {
        console.log('subscrito');
        this.document.location.href =
          global.entorno == 'produccion'
            ? global.redirecciones(this.identity.spa_list)
            : global.redireccionesPreproduccion(this.identity.spa_list);
        this.updateSubscription?.unsubscribe();
      }
    });
  }

  loadUser() {
    this.identity = this._userService.getIdentity();
    this.token = this._userService.getToken();
    if (this.identity && this.token) {
      // vamos a la spa y ya
      this.ventanaLogin = false;
    } else {
      this.ventanaLogin = true;
      // Mostramos inicio de sesión
    }
  }

  /*
  checkLastAutoOutApp() {
    console.log(location.pathname);    
    this._route.params.subscribe((params: any) => {
      let app = params['app']; // Poniendole el "+" delante lo casteo a INT
      console.log("he leido:", app);
      if (app) {
        localStorage.setItem('redirect', app);
        window.self.close();
      }
    });
  }
  */

}
