<div class="container">
  <br>
  <br>

  <div class="center-full">
    <img src="assets/images/big.jpg" class="d-inline-block align-top" alt="">
  </div>

  <ng-container *ngIf="ventanaLogin">
    <div class="center-full">
      <login [logged]="!ventanaLogin" (loginByNFC)="switchToNFC()"></login>
    </div>
    <!-- <ng-container *ngIf="loginByNfc">
      <app-nfc-modal></app-nfc-modal>
    </ng-container> -->
  </ng-container>

  <!-- vista de carga para el logado -->
  <ng-container *ngIf="cargando">
    <br>
    <br>
    <div class="center-full">
      <p-progressSpinner></p-progressSpinner>
      <h3>Cargando App</h3>
    </div>
  </ng-container>

  <!-- vista de selección de app -->
  <ng-container *ngIf="!ventanaLogin">
      <mosaic></mosaic>
  </ng-container>

  <router-outlet></router-outlet>


</div>