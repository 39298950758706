<p-toast></p-toast>
<br>
<br>
<!-- <div class="p-d-flex p-ai-start"> -->

<ng-container *ngIf="identity && identity.status == 'ONLINE'; else offline">
    <div class="p-grid p-jc-center">
        <ng-container *ngFor="let app of appList">
            <ng-container *ngIf="app.app != 'set-online'">
                <div class="p-col-12 p-sm-6 p-md-6 p-lg-4 p-xl-3" (click)="selected(app)" style="width: fit-content;">
                    <div style="height: fit-content; width: fit-content; text-align:center">
                        <p-card header={{app.title}}
                            [style]="{'width': '14rem', 'margin-bottom': '2em', 'align':'center'}">
                            <ng-template pTemplate="header">
                                <ng-container *ngIf="app.type == 'function'; else app1">
                                    <i *ngIf="app.app =='set-offline'" class={{app.icon}}
                                        style="margin-top: 20px; font-size: 7rem; color: rgb(212, 84, 84);"></i>
                                </ng-container>
                                <ng-template #app1>
                                    <i *ngIf="app.available" class={{app.icon}}
                                        style="margin-top: 20px; font-size: 7rem; color: royalblue;"></i>
                                    <i *ngIf="!app.available" class={{app.icon}}
                                        style="margin-top: 20px; font-size: 7rem; color: rebeccapurple;"></i>
                                </ng-template>
                            </ng-template>
                        </p-card>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-container>
<ng-template #offline>
    <div class="p-grid p-jc-center">
        <ng-container *ngFor="let app of appList">
            <ng-container *ngIf="app.app == 'set-online' || app.app == 'age' || app.app == 'ghp'">
                <div class="p-col-12 p-sm-6 p-md-6 p-lg-4 p-xl-3" (click)="selected(app)" style="width: fit-content;">
                    <div style="height: fit-content; width: fit-content; text-align:center">
                        <p-card header={{app.title}}
                            [style]="{'width': '14rem', 'margin-bottom': '2em', 'align':'center'}">
                            <ng-template pTemplate="header">
                                <ng-container *ngIf="app.type == 'function'; else app2">
                                    <i *ngIf="app.app =='set-online'" class={{app.icon}}
                                        style="margin-top: 20px; font-size: 7rem; color: rgb(77, 233, 124);"></i>
                                </ng-container>
                                <ng-template #app2>
                                    <i *ngIf="app.available" class={{app.icon}}
                                        style="margin-top: 20px; font-size: 7rem; color: royalblue;"></i>
                                    <i *ngIf="!app.available" class={{app.icon}}
                                        style="margin-top: 20px; font-size: 7rem; color: rebeccapurple;"></i>
                                </ng-template>
                            </ng-template>
                        </p-card>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </div>
</ng-template>
<!-- </div> -->