import { DOCUMENT } from '@angular/common';
import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { interval, Subscription } from 'rxjs';

import { User } from '../../models/user';

import { UserService } from '../../services/user.service';
import { global } from '../../services/global';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [UserService],
})
export class LoginComponent {
  @Input() logged: boolean;
  @Input() useNFC: boolean;
  @Output() ventanaLogin: boolean = true;
  @Output() loginByNFC = new EventEmitter<boolean>();

  private updateSubscription: Subscription | undefined;

  public user: User;
  public token: any;
  public identity: any;
  public workplace: any;
  public userStand: any;

  constructor(
    private _userService: UserService,
    private _router: Router,
    private _route: ActivatedRoute,
    @Inject(DOCUMENT) private document: Document
  ) {
    this.user = new User(1, '', '', '', '', 0, '');
    this.logged = false;
    this.useNFC = false;
  }

  onSubmit(form: any) {
    if (this.getToken()) {
      this.ventanaLogin = false;
      //this.iniciarCarga();
    } else console.log('Error de inicio de sesión');
  }

  iniciarCarga() {
    this.updateSubscription = interval(200).subscribe((val) => {
      console.log('subscrito');
      if (this.ventanaLogin == false) {
        this.document.location.href =
          global.entorno == 'produccion'
            ? global.redirecciones(this.identity.spa_list)
            : global.redireccionesPreproduccion(this.identity.spa_list);
        this.updateSubscription?.unsubscribe();
      }
    });
  }

  startNFCLogin() {
    console.log('iniciando');
    this.loginByNFC.emit(true);
  }

  private getToken(): boolean {
    let valido = false;
    this._userService.signup(this.user).subscribe(
      (response) => {
        // TOKEN
        if (response.status != 'error') {
          this.token = response;
          // Recojo el usuario;
          valido = this.getIdentidad();
        }
      },
      (error) => {
        console.log(<any>error);
      }
    );
    return valido;
  }

  private getIdentidad() {
    let valido = false;
    this._userService.signup(this.user, true).subscribe(
      (response) => {
        this.identity = response;

        // Persisto los datos del usuario identificado
        localStorage.setItem('token', this.token);
        localStorage.setItem('identity', JSON.stringify(this.identity));
        // Incluyo su lugar de trabajo
        valido = this.getLugarTrabajo();
      },
      (error) => {
        console.log(<any>error);
        valido = false;
      }
    );
    return valido;
  }

  private getLugarTrabajo(): boolean {
    let valido = false;
    this._userService.getUserPlace(this.identity.employee_id).subscribe(
      (response) => {
        this.workplace = response;
        console.log('workplace:', this.workplace);

        localStorage.setItem(
          'workplace',
          JSON.stringify(this.workplace.place[0])
        );
        valido = true;
        console.log('rol:', this.identity.role);
        if (this.identity.spa_list.includes('COLA')) {
          this.getCola();
        }
      },
      (error) => {
        console.log(<any>error);
        valido = false;
      }
    );
    return valido;
  }

  private getCola() {
    let valido = false;
    this._userService
      .getUserStand(parseInt(this.identity.employee_id))
      .subscribe(
        (response) => {
          this.userStand = response.stand[0];
          console.log(response);
          localStorage.setItem('userStand', JSON.stringify(this.userStand));
          valido = true;
        },
        (error) => {
          console.log(<any>error);
        }
      );
    return valido;
  }

  /*
  onSubmit(form: any) {
    // this._userService.signup(this.user).subscribe(
    //   response => {
    //     // TOKEN
    //     if (response.status != 'error') {
    //       this.token = response;

    //       // Objeto Usuario Identificado
    //       this._userService.signup(this.user, true).subscribe(
    //         response => {
    //           this.identity = response;

    //           // Persisto los datos del usuario identificado
    //           localStorage.setItem('token', this.token);
    //           localStorage.setItem('identity', JSON.stringify(this.identity));
    //           // Incluyo su lugar de trabajo
    //           this._userService.getUserPlace(this.identity.employee_id).subscribe(
    //             response => {
    //               this.workplace = response;
    //               localStorage.setItem('workplace', JSON.stringify(this.workplace.place[0]));
    //               if (this.identity.role == "COLA") {
    //                 this._userService.getUserStand(this.identity.employee_id).subscribe(
    //                   response => {
    //                     this.userStand = response.stand[0];
    //                     console.log(response);
    //                     localStorage.setItem('userStand', JSON.stringify(this.userStand));
    //                   },
    //                   error => {
    //                     console.log(<any>error);
    //                   }
    //                 );
    //               }
    //             },
    //             error => {
    //               console.log(<any>error);
    //             }
    //           );

    //           // Redirigir a la spa
    //           this._router.navigate(['inicio']);
    //         },
    //         error => {
    //           console.log(<any>error);
    //         }
    //       );
    //     } else {
    //     }
    //   },
    //   error => {
    //     console.log(<any>error);
    //   }
    // );
  }

  */
}
