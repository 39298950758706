import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { ButtonModule } from 'primeng/button';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CardModule } from 'primeng/card';
import { ToastModule } from 'primeng/toast';
import { DialogModule } from 'primeng/dialog';

import { IdentityGuard } from './services/identity.guard';
import { UserService } from './services/user.service';

import { LoginComponent } from './components/login/login.component';
import { AppMosaicComponent } from './components/app-mosaic/app-mosaic.component';
import { NfcModalComponent } from './components/nfc-modal/nfc-modal.component';
import { NfcCheckComponent } from './components/nfc-check/nfc-check.component';

@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    AppMosaicComponent,
    NfcModalComponent,
    NfcCheckComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    ProgressSpinnerModule,
    CardModule,
    ToastModule,
    FormsModule,
    DialogModule
  ],
  providers: [IdentityGuard, UserService],
  bootstrap: [AppComponent],
})
export class AppModule {}
