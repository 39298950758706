  <div class="boxed">

    <form #loginForm="ngForm" (ngSubmit)="onSubmit(loginForm)">
      <div class="form-group">
        <h4>Correo</h4>
        <input type="email" name="email" class="form-control" #email="ngModel" [(ngModel)]="user.email" required
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$">
        <small *ngIf="!email.valid && email.touched" class="invalid-feedback d-block">
          El correo no es válido
        </small>
      </div>
      <div class="form-group">
        <h4>Contraseña</h4>
        <input type="password" name="password" class="form-control" #password="ngModel" [(ngModel)]="user.password"
          required>
        <small *ngIf="!password.valid && password.touched" class="invalid-feedback d-block">
          La contraseña no es válida
        </small>
      </div>

      <div>
        <input type="submit" value="Iniciar Sesión" class="btn btn-primary" [disabled]="loginForm.invalid" />
      </div>

    </form>

  </div>
