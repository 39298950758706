import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../models/user';
import { global } from './global';

@Injectable()
export class UserService {
  public url: string;
  public identity: any;
  public token: any;
  public workplace: any;
  public userStand: any;

  constructor(public _http: HttpClient) {
    this.url = global.url;
  }

  test() {
    return 'Hola Mundo desde un Servicio!!';
  }

  // Esto debe ser borrado !!! PELIGROSSISIMO
  getUsers(token: string): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-type', 'application/x-www-form-urlencoded')
      .set('Authorization', token);

    return this._http.get(this.url + 'user/getAll', { headers: headers });
  }

  getUsersLogs(token: string): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-type', 'application/x-www-form-urlencoded')
      .set('Authorization', token);

    return this._http.get(this.url + 'user/log/resume', { headers: headers });
  }

  register(token: string, user: any): Observable<any> {
    let json = JSON.stringify(user);
    let params = 'json=' + json;

    let headers = new HttpHeaders()
      .set('Content-type', 'application/x-www-form-urlencoded')
      .set('Authorization', token);

    return this._http.post(this.url + 'register', params, { headers: headers });
  }

  passChange(token: string, user: any): Observable<any> {
    let json = JSON.stringify(user);
    let params = 'json=' + json;

    let headers = new HttpHeaders()
      .set('Content-type', 'application/x-www-form-urlencoded')
      .set('Authorization', token);

    return this._http.post(this.url + 'user/changePass', params, {
      headers: headers,
    });
  }

  checkNFCToken(nfctoken: string, gettoken = false): Observable<any> {
    let nfctokenized;
    if (gettoken != false) {
      nfctokenized = { nfctoken: nfctoken, gettoken: true };
    } else {
      nfctokenized = { nfctoken: nfctoken };
    }

    let json = JSON.stringify(nfctokenized);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set(
      'Content-type',
      'application/x-www-form-urlencoded'
    );

    return this._http.post(this.url + 'nfc-login', params, {
      headers: headers,
    });
  }

  signup(user: any, gettoken = false): Observable<any> {
    if (gettoken != false) {
      user.gettoken = 'true';
    }

    let json = JSON.stringify(user);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set(
      'Content-type',
      'application/x-www-form-urlencoded'
    );

    return this._http.post(this.url + 'login', params, { headers: headers });
  }

  signout(user_id: number): Observable<any> {
    let headers = new HttpHeaders().set(
      'Content-type',
      'application/x-www-form-urlencoded'
    );
    console.log(user_id);

    return this._http.get(this.url + 'user/markout/' + user_id, {
      headers: headers,
    });
  }

  update(token: string, user: any): Observable<any> {
    let json = JSON.stringify(user);
    let params = 'json=' + json;

    let headers = new HttpHeaders()
      .set('Content-Type', 'application/x-www-form-urlencoded')
      .set('Authorization', token);

    return this._http.put(this.url + 'user/update', params, {
      headers: headers,
    });
  }

  getIdentity() {
    let test = localStorage.getItem('identity');
    if (test != null) {
      let identity = JSON.parse(test);

      if (identity && identity != 'identity') {
        this.identity = identity;
      } else {
        this.identity = null;
      }

      return this.identity;
    }
  }

  getToken() {
    let token = localStorage.getItem('token');

    if (token != 'undefined') {
      this.token = token;
    } else {
      this.token = null;
    }

    return this.token;
  }

  getUserPlace(id: number): Observable<any> {
    let headers = new HttpHeaders().set(
      'Content-type',
      'application/x-www-form-urlencoded'
    );

    return this._http.get(this.url + 'user/place/' + id, { headers: headers });
  }

  getWorkplace() {
    let test = localStorage.getItem('workplace');
    if (test != null) {
      let workplace = JSON.parse(test);

      if (workplace && workplace != 'workplace') {
        this.workplace = workplace;
      } else {
        this.workplace = null;
      }

      return this.workplace;
    }
  }

  getUserStand(id: number): Observable<any> {
    let headers = new HttpHeaders().set(
      'Content-type',
      'application/x-www-form-urlencoded'
    );

    return this._http.get(this.url + 'user/stand/' + id, { headers: headers });
  }

  getStand() {
    let test = localStorage.getItem('userStand');
    if (test != null) {
      let userStand = JSON.parse(test);

      if (userStand && userStand != 'userStand') {
        this.userStand = userStand;
      } else {
        this.userStand = null;
      }

      return this.userStand;
    }
  }

  startWork(id: number, token: string): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-type', 'application/x-www-form-urlencoded')
      .set('Authorization', token);

    return this._http.get(this.url + 'user/start/' + id, { headers: headers });
  }

  stopWork(id: number, token: string): Observable<any> {
    let headers = new HttpHeaders()
      .set('Content-type', 'application/x-www-form-urlencoded')
      .set('Authorization', token);

    return this._http.get(this.url + 'user/stop/' + id, { headers: headers });
  }
}
