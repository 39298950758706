import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { IdentityGuard } from './services/identity.guard';

import { AppComponent } from './app.component';
import { NfcCheckComponent } from './components/nfc-check/nfc-check.component';
import { NfcModalComponent } from './components/nfc-modal/nfc-modal.component';

const routes: Routes = [
  {
    path: 'comprobar-nfc/:token',
    component: NfcModalComponent,
  },
  // {
  //   path: 'ultima-app/:app',
  //   component: AppComponent,
  // },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
