export class User{
    public spa_list: Array<string> = [];

    constructor(
        public id: number,
        public name: string,
        public email: string,
        public password: string,
        public role: string,
        public employee_id: number,
        public image: string
    ) {

    }


}